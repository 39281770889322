import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

import tableStyles from './post.module.scss';

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
        frontmatter {
            title
            date
        }
        html
        }
    }
`

const Blog = (props) => {
    return (
        <Layout>
            <h1>{props.data.markdownRemark.frontmatter.title}</h1>
            <p>{props.data.markdownRemark.frontmatter.date}</p>
            <div className={tableStyles.div}>
                <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
            </div>
        </Layout>
    )
}

export default Blog